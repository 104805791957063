<template>
    <div>
        <div class="icon-container" @click="show = !show; trackClick('header', 'Buscar en el sitio')">
            <img v-img-prefix :src="`/public-assets/search-white`" alt="search-icon" class="icon" width="24"
                 height="23">
            <div v-show="!show">
                <div class="caret"></div>
                <div class="icon-content">
                    <span>Buscar en el sitio</span>
                </div>
            </div>
        </div>
        <div v-if="show">
            <SearchContainer :sections="sections" :trending="trending"/>
        </div>
    </div>
</template>

<script>
import SearchContainer from "@/components/search/SearchContainer.vue";
import {trackClick} from "@/helpers/clickTracker";

export default {
    methods: {trackClick},
    components: {SearchContainer},
    props: {
        sections: Array,
        trending: Array,
    },
    data() {
        return {
            show: false,
        }
    },
    mounted() {
        this.$bus.$on('close-search', () => {
            this.show = false
            let body = document.getElementsByTagName('body')[0]
            body.style.overflowY = 'scroll'
        })
    }
}
</script>